const lunr = require("lunr");
require("lunr-languages/lunr.stemmer.support")(lunr);
require("lunr-languages/lunr.de.js")(lunr);
require("lunr-languages/lunr.multi.js")(lunr);

async function loadSearch() {
  let [index, store] = await Promise.all([
    fetch("/search-index.json").then((response) => response.json()),
    fetch("/search-data.json").then((response) => response.json()),
  ]);

  return { index, store };
}

function highlight(field, storeItem, metadata) {
  const text = storeItem[field];

  for (const key of Object.keys(metadata)) {
    if (!metadata[key][field]) return text.substring(0, 200);

    const position = metadata[key][field].position;
    const substring = text.substring(
      position[0][0],
      position[0][0] + position[0][1],
    );

    if (!substring) return text.substring(0, 200);

    const start = position[0][0] - 100;
    const end = start + position[0][1] + 200;

    return text
      .substring(start, end)
      .replace(substring, `<mark>${substring}</mark>`);
  }
}

function autoComplete(idx, searchTerm) {
  const results = idx.query((q) => {
    // exact matches should have the highest boost
    q.term(searchTerm, { boost: 100 });
    // wildcard matches should be boosted slightly
    q.term(searchTerm, {
      boost: 10,
      usePipeline: true,
      wildcard: lunr.Query.wildcard.TRAILING,
    });
    // finally, try a fuzzy search, without any boost
    // q.term(searchTerm, { boost: 1, usePipeline: false, editDistance: 1 });
  });

  if (!results.length) return "";

  return results
    .map((v) => {
      // extract unstemmed terms
      const unstemmedTerms = {};
      Object.keys(v.matchData.metadata).forEach((term) => {
        Object.keys(v.matchData.metadata[term]).forEach((field) => {
          v.matchData.metadata[term][field].unstemmed.forEach(
            (word) => (unstemmedTerms[word] = true),
          );
        });
      });
      return Object.keys(unstemmedTerms);
    })
    .reduce((a, b) => {
      // flatten
      return a.concat(b);
    })
    .filter((v, i, a) => {
      // uniq
      return a.indexOf(v) === i;
    })
    .slice(0, 8);
}

const DEFAULT_HINT = "<p><small>Bitte Suchbegriff eingeben.</small></p>";

document.addEventListener("DOMContentLoaded", () => {
  const searchInput = document.querySelector("#search");

  if (searchInput) {
    const resultElement = document.querySelector("#results");
    const suggestionsElement = document.querySelector("#suggestions");

    resultElement.innerHTML = DEFAULT_HINT;

    loadSearch().then(({ index, store }) => {
      lunr.multiLanguage("en", "de");
      const idx = lunr.Index.load(index);

      searchInput.addEventListener("input", () => {
        suggestionsElement.innerHTML = "";
        resultElement.innerHTML = DEFAULT_HINT;

        // Get query
        const query = searchInput.value.toLowerCase();
        if (!query) {
          resultElement.innerHTML = DEFAULT_HINT;
          return;
        }

        const suggestions = autoComplete(idx, query);
        for (var suggestion of suggestions)
          suggestionsElement.innerHTML += `<option>${suggestion}</option>`;
      });

      ["change", "keyup"].forEach((name) => {
        searchInput.addEventListener(name, (e) => {
          // Normaly, listening to "change" would be enough,
          // but we want to react to "Enter" key, which is a `Event`, not a `KeyboardEvent`
          if (e instanceof KeyboardEvent && e.code !== "Enter") return;

          suggestionsElement.innerHTML = "";

          // Get query
          const query = searchInput.value;
          if (!query) {
            resultElement.innerHTML = DEFAULT_HINT;
            return;
          }

          // Search for it
          const result = idx.search(query);

          // Show results
          resultElement.innerHTML = `<p class="mb-10"><small>${result.length} Fundstellen<small></p>`;
          for (var resultItem of result) {
            const storeItem = store[resultItem.ref];

            const articleElement =
              '<article class="mb-10 container"><h2 class="text-xl"><a href="' +
              storeItem.url +
              '">' +
              highlight("title", storeItem, resultItem.matchData.metadata) +
              "</a></h2>" +
              "<div>&hellip; " +
              highlight("content", storeItem, resultItem.matchData.metadata) +
              " &hellip;</div></article>";

            resultElement.innerHTML += articleElement;
          }
        });
      });
    });
  }
});
