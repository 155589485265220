import tippy from "tippy.js";

// Full filenames required here because of digester
const mapping = {
  Ruby: "/assets/images/languages/ruby.svg",
  TypeScript: "/assets/images/languages/typescript.svg",
  "Vue.js": "/assets/images/languages/vue.js.svg",
  Slim: "/assets/images/languages/slim.svg",
  YAML: "/assets/images/languages/yaml.svg",
  JavaScript: "/assets/images/languages/javascript.svg",
  JSON: "/assets/images/languages/json.svg",
  Markdown: "/assets/images/languages/markdown.svg",
  HTML: "/assets/images/languages/html.svg",
  ERB: "/assets/images/languages/erb.svg",
  Docker: "/assets/images/languages/docker.svg",
  Svelte: "/assets/images/languages/svelte.svg",
  Astro: "/assets/images/languages/astro.svg",
  CSS: "/assets/images/languages/css.svg",
  Python: "/assets/images/languages/python.svg",
  MDX: "/assets/images/languages/mdx.svg",
  Bash: "/assets/images/languages/shell.svg",
  Other: "/assets/images/languages/other.svg",

  // TODO: Add more languages from here:
  // https://github.com/vscode-icons/vscode-icons/tree/master/icons
};

document.addEventListener("DOMContentLoaded", () => {
  const element = document.getElementById("wakatime");

  if (element)
    fetch("/languages.json")
      .then((response) => response.json())
      .then(({ data }) => drawChart(element, fixOther(data)));
});

function drawChart(element, data) {
  data.forEach((item) => {
    const childElement = document.createElement("div");
    childElement.className =
      "flex items-center justify-center py-1 cursor-default";
    childElement.style.backgroundColor = item.color;

    if (item.percent > 20) childElement.innerHTML = `<span>${item.name}</span>`;
    else if (item.percent > 10)
      childElement.innerHTML = `<span class="hidden sm:inline">${item.name}</span>`;

    element.appendChild(childElement);
    const imgUrl = mapping[item.name] || mapping.other;

    tippy(childElement, {
      content: `
        <div class="text-center">
          <img class="h-24 w-24" src="${imgUrl}" />
          <strong>${item.name}</strong>
          <br/>
          ${Math.round(item.percent)}%
        </div>`,
      placement: "bottom",
      allowHTML: true,
      theme: "light-border",
    });
  });

  element.style.gridTemplateColumns = data
    .map((item) => `${item.percent}fr`)
    .join(" ");
}

function fixOther(wakatimeData) {
  // There is already an item called "Other"
  const other = wakatimeData.find((item) => item.name === "Other");

  // Sum up the other items with low usage (< 1 %)
  const otherSum =
    other.percent +
    wakatimeData
      .filter((item) => item.percent < 1 && item.name !== other.name)
      .reduce((total, item) => total + item.percent, 0);

  // Build new array without others
  const filteredData = wakatimeData.filter(
    (item) => item.percent >= 1 && item.name !== other.name,
  );

  // Add new "other" item
  filteredData.push({
    percent: otherSum,
    name: other.name,
    color: other.color,
  });

  return filteredData;
}
