import Plausible from "plausible-tracker";

const domain = window.location.hostname;
const { enableAutoPageviews, enableAutoOutboundTracking } = Plausible({
  domain,
  apiHost: `https://p.${domain}`,
});

enableAutoPageviews();
enableAutoOutboundTracking();
