import tippy from "tippy.js";

let queryGithub = (element) => {
  setTimeout(() => {
    const repos = element.getAttribute("data-github-repos");

    Promise.all(
      repos.split(",").map((repo) =>
        fetch("https://ledermann.dev/github/".concat(repo))
          .then((response) => response.json())
          .then((json) => json.stargazers_count),
      ),
    ).then((counts) => {
      const sum = counts.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0,
      );

      element.innerHTML = `&#9733;&nbsp;${sum}`;

      if (counts.length > 1) {
        element.setAttribute("data-tippy-content", counts.join(" + "));
        element.classList.remove("block");

        tippy(element, {
          placement: "left",
        });
      }
    });
  }, 1000);
};

let onChange = (changes, observer) => {
  changes.forEach(({ intersectionRatio, target }) => {
    if (intersectionRatio > 0) {
      queryGithub(target);
      observer.unobserve(target);
    }
  });
};

document.addEventListener("DOMContentLoaded", () => {
  const repoElements = document.querySelectorAll("[data-github-repos]");

  if ("IntersectionObserver" in window) {
    // IntersectionObserver supported
    const observer = new IntersectionObserver(onChange);
    repoElements.forEach((element) => observer.observe(element));
  } else {
    // IntersectionObserver NOT supported
    repoElements.forEach((element) => queryGithub(element));
  }
});
